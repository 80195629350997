<template>
    <section class="contacts">
        <h1 class="contacts__title">Aktywność użytkowników</h1>

        <section class="chart_section" v-if="chartData.datasets[0].data.length > 0">
            <h3>Ilość logowań użytkowników z ostatnich 3 miesięcy</h3>
            <Chart :chartData="chartData" :plugins="plugins" :type="0" />
        </section>
        <section class="table_section" v-if="dataArr.length > 0">
            <h3>Wizyty</h3>
            <TableComponent :search="search" :pagination="pagination" :fields="fields" :dataArr="dataArr" @callAction="showUserLastLogins" />
        </section>
        <Transition>
            <UserLastLoginsModal v-if="showLastUsersLoginsModal" @closeUserLogs="closeModal" :user="tempUserName" :dates="lastUserLoginsArr" :userid="tempUserId" />
        </Transition>
    </section>

</template>

<script>
    import ToolsService from "../../../../services/tools.service";
    import Chart from '../../../BasicControls/Charts/Chart.vue';
    import TableComponent from '../../../../components/Table/TableComponent.vue'
    import UserLastLoginsModal from '../Tools/Modals/UserLastLoginsModal.vue';
    export default {
        name: "activity",
        computed: {
            isAnyModalOpen() {
                return this.showLastUsersLoginsModal
            }
        },
        data() {
            return {
                chartData: {
                    labels: [],
                    datasets: [{ data: [] }]

                },
                plugins: [],
                tempdata: [],
                complex: {},
                dataArr: [],
                lastUserLoginsArr: [],
                showLastUsersLoginsModal: false,
                tempUserId: 0,
                tempUserName: '',
                pagination: true,
                fields: ['ID', 'Imię', 'Nazwisko', 'Wizyty', 'Ostatnie logowanie', 'Ostatnia aktywność', '&Logowania'],
                search: true
            };
        },
        watch: {
            isAnyModalOpen: {
                deep: true,
                handler: function (val) {
                    document.querySelector('body').style.overflow = val ? 'hidden' : 'auto';
                }
            },
        },
        methods: {
            formatDateToDisplay(value) {
                var date = new Date(value)
                var d = date.toLocaleString('default', {
                    year: 'numeric',
                    month: 'long',
                    weekday: 'long',
                    day: 'numeric'
                });

                return d;
            },
            formatDateToDisplayWithHour(value) {
                var date = new Date(value)
                var d = date.toLocaleString('default', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    weekday: 'long',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'
                });

                return d;
            },
            closeModal() {
                this.showLastUsersLoginsModal = false;
            },
            async showUserLastLogins(item) {
                try {
                    this.$store.commit("showLoader");
                    var user = this.complex.users.find(x => x.id == item.ID.raw);
                    if (user != null) {
                        this.lastUserLoginsArr = [];
                        this.tempUserName = user.firstName + ' ' + user.lastName;
                        this.tempUserId = user.id;
                        var logins = (await ToolsService.getUserLastLogins(user.id, 1)).data;

                        if (logins.length > 0) {
                            for (var i = 0; i < logins.length; i++) {
                                var t = {
                                    Data: this.createObj(logins[i].date, this.formatDateToDisplayWithHour(logins[i].date)),
                                    'Adres IP': this.createObj(logins[i].ipAddress, logins[i].ipAddress),
                                };
                                this.lastUserLoginsArr.push(t);
                            }
                        } else {
                            var t2 = {
                                Data: this.createObj('0', 'Brak danych')
                            };
                            this.lastUserLoginsArr.push(t2);
                        }

                        this.$store.commit("hideLoader");
                        this.showLastUsersLoginsModal = true;
                    }
                } catch (e) {
                    if (e.response.status == 401)
                        this.$router.push({ name: 'Login' })
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
            createObj(_raw, _parsed) {
                return {
                    raw: _raw,
                    parsed: _parsed
                };
            }
        },
        components: {
            Chart,
            TableComponent,
            UserLastLoginsModal
        },
        async mounted() {
            try {
                this.$store.commit("showLoader");
                let response = await ToolsService.getUsersActivityData();
                if (response.status !== 200) {
                    throw await Promise.reject(response);
                }

                this.complex = response.data;

                this.dataArr = this.complex.users.map(item => {
                    return {
                        ID: this.createObj(item.id, item.id),
                        Imię: this.createObj(item.firstName, item.firstName),
                        Nazwisko: this.createObj(item.lastName, item.lastName),
                        Wizyty: this.createObj(item.visitsCount, item.visitsCount),
                        'Ostatnie logowanie': this.createObj(item.lastLoginDate !== null ? item.lastLoginDate : '0', item.lastLoginDate != null ? this.formatDateToDisplay(item.lastLoginDate) : "-"),
                        'Ostatnia aktywność': this.createObj(item.lastActivity !== null ? item.lastActivity : '0', item.lastActivity != null ? this.formatDateToDisplay(item.lastActivity) : "-"),
                        '&Logowania': 'action',
                    };
                });



                //for (var j = 0; j < this.complex.users.length; j++) {
                //    var temp = {
                //        ID: this.complex.users[j].id,
                //        Imię: this.complex.users[j].firstName,
                //        Nazwisko: this.complex.users[j].lastName,
                //        Wizyty: this.complex.users[j].visitsCount,
                //        'Ostatnie logowanie': this.complex.users[j].lastLoginDate != null ? this.formatDateToDisplay(this.complex.users[j].lastLoginDate) : "-",
                //        'Ostatnia aktywność': this.complex.users[j].lastActivity != null ? this.formatDateToDisplayWithHour(this.complex.users[j].lastActivity) : "-",
                //        '&Logowania': 'action',
                //    };
                //    this.dataArr.push(temp);
                //}

                for (var i = 0; i < this.complex.chart.length; i++) {
                    this.chartData.labels.push(this.complex.chart[i].label);
                    this.tempdata.push(this.complex.chart[i].data);
                    this.chartData.datasets[0].data = this.tempdata;
                    this.chartData.datasets[0].backgroundColor = '#33599e';
                    this.chartData.datasets[0].label = 'Ilość';
                }
                this.pagination = false;
                
            } catch (e) {
                if (e.response.status == 401)
                    this.$router.push({ name: 'Login' })
            }
            finally {
                this.$store.commit("hideLoader");
            }
        },
    };
</script>

<style lang="scss" scoped>
    .table_section {
        background-color: white;
        border-radius: 10px;
        padding: 10px;
        margin-top: 20px;
    }

    .chart_section {
        background-color: white;
        border-radius: 10px;
        max-height: 500px;
        padding: 10px;
    }

    .contacts {
        margin: 49px 48px;

        &__title {
            font-weight: 600;
            font-size: 28px;
            line-height: 128%;
            color: #00082c;
            margin-bottom: 20px;
        }

        &__add-user-btn {
            position: fixed;
            bottom: 40px;
            right: 48px;
            width: 56px;
            height: 56px;
            border: 0px;
            background: #d80418;
            box-shadow: 8px 8px 28px rgba(173, 3, 19, 0.16);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.2 ease-in-out;

            &:hover {
                background: #ad0313 !important;
            }
        }

        &__add-user-btn-icon {
            width: 25px;
            height: 24px;
        }
    }

    .contact-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        &__input {
            height: 48px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            width: 326px;
            box-sizing: border-box;
            padding: 12.5px 16px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: 0px;

            &:focus {
                border: 1px solid #00082c;
            }
        }

        &__button-search {
            height: 48px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.08);
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-left: 0px;
            position: relative;
            cursor: pointer;

            &::after {
                content: "";
                position: absolute;
                width: 1px;
                height: 32px;
                background: #e2e5eb;
                top: 8px;
                left: 0px;
            }
        }

        &__form {
            display: flex;
            align-items: center;
        }

        &__search-icon {
            padding: 14px;
        }

        &__action-buttons {
            display: flex;
        }
    }

    .contact-list-no-result {
        width: 100%;
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
