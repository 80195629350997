<template>
    <section class="esesja-modal">
        <section class="esesja-modal__wraper">
            <section class="esesja-modal__wraper__header">
                <h3 class="esesja-modal__wraper__header__title">Edytuj głosowanie</h3>
                <button class="esesja-modal__wraper__header__exit-btn"
                        @click=" (e)=>{e.preventDefault(); this.$emit('closeEditVotingNameModal');}">
                    <img src="../../../../../assets/icons/components/exit-btn.svg" />
                </button>
            </section>
            <perfect-scrollbar class="esesja-modal__wraper__body">
                <label class="esesja-modal__wraper__body__label">Nazwa głosowania</label>
                <section class="esesja-modal__row">
                    <textarea
                           rows="5"
                           placeholder="Wpisz nazwę głosowania..."
                           class="esesja-modal__wraper__body__input"
                           v-model="name"></textarea>
                </section>

                <section style="margin-bottom: 20px;" class="d-flex flex-column gap-2">
                    <p>Przeprowadził: <b>{{metadata.adminName}}</b></p>
                    <p>Czas startu głosowania: <b>{{formatDate(metadata.startDate, 'yyyy-MM-DD HH:mm:ss')}}</b></p>
                    <p>Czas zapisu głosowania: <b>{{formatDate(metadata.endDate, 'yyyy-MM-DD HH:mm:ss')}}</b></p>
                    <p>Jawność głosowania: <b>{{metadata.isPublic ? 'Jawne' : 'Tajne'}}</b></p>
                    <p>Tryb głosowania: <b>{{metadata.mode > 1 ? metadata.modeName : 'Brak'}}</b></p>
                    <p>Wyłączeni z głosowania: <b>{{metadata.excluted != null && metadata.excluted.length > 0 ? exclutedToDisplay(metadata.excluted) : 'Brak'}}</b></p>
                </section>

                <section class="esesja-modal__wraper__body__bottom">
                    <button class="esesja-modal__wraper__body__bottom-button"
                            @click="this.$emit('closeEditVotingNameModal')">
                        Anuluj
                    </button>
                    <button class="
                        esesja-modal__wraper__body__bottom-button
                        esesja-modal__wraper__body__bottom-button--red
                    "
                            @click="handleSaveClick" :disabled="name.length == 0">
                        Zapisz
                    </button>
                </section>
            </perfect-scrollbar>

        </section>
        <LoaderInside v-if="isLoading" />
    </section>
</template>

<script charset="utf-8">
import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
import VotingService from "../../../../../services/voting.service";
import { encryptStorage } from '../../../../../services/encrypt.service';
import moment from "moment";

export default {
    name: "EditVotingNameModal",
    data() {
        return {
            name: this.tempVotingNameToEdit,
            isLoading: false,
            votingModule: encryptStorage.getItem('modules').g,
            metadata: {
                adminName: '',
                startDate: '',
                endDate: '',
                isPublic: 1,
                mode: 1,
                modeName: 'Brak Trybu',
                excluted: [
                    {
                        id: 0,
                        name: ''
                    }
                ]
            },
        };
    },
    props: {
        tempVotingNameToEdit: String,
        tempVotingId: Number,
        pointID: Number,
        session: Number
    },
    components: {
        LoaderInside,
    },
    async mounted() {
        await this.getMetadata();
    },
    methods: {
        async getMetadata() {
            try {
                if (this.votingModule == 1 || this.tempVotingId > 0) {

                    let result = await VotingService.getVotingMetadata(this.tempVotingId, this.pointID, this.session)

                    if (result.status == 200) {
                        this.metadata = result.data;
                    }
                }
            }
            catch (e) {
                if (e.response.status == 401)
                    this.$router.push({ name: 'Login' })
            }
        },
        formatDate(date, format) {
            return moment(date).format(format);
        },
        exclutedToDisplay(excluted) {
            var str = ''

            for (var i = 0; i < excluted.length; i++) {
                str += excluted[i].name + ", ";
            }

            return str.replace(/,\s*$/, "");
        },
        handleSaveClick() {
            this.isLoading = true;
            const data = {
                id: this.tempVotingId,
                name: this.name,
                sid: this.session
            };

            VotingService.editVotingName(this.pointID, data).then(() => {
                this.name = "";
                this.$emit("reload");
                this.$emit("closeEditVotingNameModal");
                this.isLoading = false;
            });
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "../../../../../assets/scss/esesja-modal.scss";

    .esesja-modal__wraper__body__input{
        width: 600px;
    }
</style>

