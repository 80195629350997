<template>
    <section class="session-item">
        <section class="session-item__left gap-2">
            <section class="session-item__left__item d-flex flex-column align-items-start gap-2">
                <section class="d-flex gap-2">
                    <section class="session-item__left__status">
                        <img v-if="!session.is_public && !session.is_authorized" src="@/assets/icons/panel/home/eye-slash.svg" v-tooltip="'Posiedzenie ukryte'" /><span v-if="!session.is_public && !session.is_authorized"></span>
                        <img v-if="!session.is_public && session.is_authorized" src="@/assets/icons/panel/home/eye.svg" v-tooltip="'Widoczne dla użytkowników'" /><span v-if="!session.is_public && session.is_authorized"></span>
                        <img v-if="session.is_public" src="@/assets/icons/panel/home/globe.svg" v-tooltip="'Posiedzenie publiczne'" /><span v-if="session.is_public && session.is_authorized"></span>
                    </section>
                    <router-link :to="{name: 'sessionEdit', params:{sessionid: session.id, groupid: session.groups[0].id }}" class="session-item__left__name">
                        {{sessionName}}
                    </router-link>
                </section>
                <section class="d-flex gap-3">
                    <section class="session-item__left__date">
                        <img src="@/assets/icons/panel/editSession/meeting-date.svg" /><span class="session-item__left__text text-nowrap">{{ getDate }}</span>
                    </section>
                    <section class="session-item__left__time">
                        <img src="@/assets/icons/panel/editSession/meeting-time.svg" /><span class="session-item__left__text text-nowrap">{{ getTime }}</span>
                    </section>
                    <section class="session-item__left__place" v-if="session.meeting_place">
                        <img src="@/assets/icons/panel/editSession/location-dot.svg" /><span class="session-item__left__text">{{ session.meeting_place }}</span>
                    </section>
                </section>
            </section>
            <section class="session-item__left__item">
                <span class="session-item__half-hidden">
                    Grupy uczestniczące:
                    <span class="item-group">
                        {{groupsName.join(', ')}}
                    </span>
                </span>
            </section>
        </section>
        <section class="session-item__right" @click="toggleContexMenuOptions">
            <SessionSearchContextMenu ref="sessionSearchContextMenuRef"
                                      :style="contextMenuStyle"
                                      :sessionId="session.id"
                                      :groupId="session.groups[0].id"
                                      :skippedActionId="session.skippedActions"
                                      :globalbrakeline="false"
                                      @actionSelected="closeContexMenuOptions" />
            <button class="session-item__right__button">
                <img src="@/assets/icons/panel/editSession/more2.svg" class="session-item__right__button__icon" style=" width: 7px;" />
            </button>
        </section>
    </section>
</template>
<script charset="utf-8">
    import SessionSearchContextMenu from '@/components/BasicControls/SessionSearchContextMenu.vue';

    //services 
    import moment from "moment";
    import { getSessionName } from '../../../../helpers/session.helper';

    export default {
        name: "SessionListItem",
        components: {
            SessionSearchContextMenu
        },
        props: {
            session: Object,
        },
        computed: {
            getDate() {
                if (this.session.end_meeting_date !== null) {
                    let splitedMeetingDate = this.session.meeting_date.split("T")[0];
                    let splitedEndMeetingDate = this.session.end_meeting_date.split("T")[0];
                    let meetingDate = new Date(splitedMeetingDate);
                    let endMeetingDate = new Date(splitedEndMeetingDate);
                    let diff = Math.abs(endMeetingDate - meetingDate);
                    const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));

                    if (diffDays === 0)
                        return `${moment(this.session.meeting_date).format("dddd")}, ${moment(this.session.meeting_date).format("DD.MM.YYYY")}`;
                    else
                        return `${moment(this.session.meeting_date).format("dddd")}, ${moment(this.session.meeting_date).format("DD.MM")} - ${moment(this.session.end_meeting_date).format("DD.MM.YYYY")}`;
                }
                else {
                    return `${moment(this.session.meeting_date).format("dddd")}, ${moment(this.session.meeting_date).format("DD.MM.YYYY")}`;
                }

            },
            getTime() {
                return moment(this.session.meeting_date).format("LT");
            },
            groupsName() {
                return this.session.groups.map(item => item.name);
            },
            sessionName() {
                return getSessionName(this.session.groups[0], this.session, 9999999999999);
            },

            isContextMenuOpen() {
                return this.contextMenuStyle.indexOf("block") !== -1;
            }
        },
        data() {
            return {
                optionsContextMenu: null,
                contextMenuStyle: "",
            }
        },
        mounted() {
            this.contextMenuStyle = this.setContextMenuStyle("none", "absolute", 0.0, 0.0);
            document.addEventListener("click", (e) => {
                if (this.isContextMenuOpen) {
                    let agendaContext = document.getElementsByClassName(`display-context-menu-${this.session.id}`);
                    let isClickInside = false;
                    for (let item of agendaContext) {
                        if (!isClickInside) {
                            isClickInside = item.contains(e.target);
                        }

                    }

                    if (!isClickInside) {
                        this.contextMenuStyle = this.setContextMenuStyle("none", "absolute", 0.0, 0.0);
                        for (let item of agendaContext) {
                            item.classList.remove(`display-context-menu-${this.session.id}`)
                        }
                    }
                }
            });
        },
        methods: {
            async toggleContexMenuOptions(e) {
                
                e.preventDefault();
                var rect = e.target.getBoundingClientRect();
                var x = -300;
                var y = e.clientY - rect.top + 35;

               /* console.log(rect);*/

                var agendaContext = document.querySelectorAll(
                    ".session-search-context-menu"
                );
                agendaContext.forEach((block) => (block.style.display = "none"));
                for (let i = 0; i < e.currentTarget.children.length; i++) {
                    e.currentTarget.children[i].classList.add(`display-context-menu-${this.session.id}`)
                }
                if (this.isContextMenuOpen) {
                    this.contextMenuStyle = this.setContextMenuStyle("none", "absolute", y, x);
                }
                else {
                    this.contextMenuStyle = this.setContextMenuStyle("block", "absolute", y, x);
                    await this.$nextTick();
                    const elementHeight = this.$refs.sessionSearchContextMenuRef.$el.clientHeight;

                    const estimatedEndContenxtMenuYPosition = e.clientY + elementHeight;
                    if(estimatedEndContenxtMenuYPosition > window.innerHeight){
                        y += (window.innerHeight - estimatedEndContenxtMenuYPosition - 50);
                        x -= 30;
                    }
                    this.contextMenuStyle = this.setContextMenuStyle("block", "absolute", y, x);
                }
               
                //this.optionsContextMenu = contextMenu;
            },

            setContextMenuStyle(display, position, top, left) {
                return `display: ${display}; position: ${position}; inset: ${top}px auto auto ${left}px;`
            },
            closeContexMenuOptions() {
                if (this.optionsContextMenu !== null) {
                    this.optionsContextMenu.style.display = "none";
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .session-item {
        background: #ffffff;
        width: 100%;
        border: 1px solid #dce4ee;
        box-sizing: border-box;
        box-shadow: 6px 6px 24px rgba(0, 8, 44, 0.03);
        border-radius: 12px;
        transition: all 0.2s ease-in-out;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px;
        align-items: self-start;
        &:hover {
            border: 1px solid #bbc2ce;
        }

        &:hover .session-item__right {
            opacity: 1;
        }

        &__half-hidden {
            color: #999;
        }

        &__left {
            display: flex;
            flex-direction: column;
            row-gap: 20px;


            &__item {
                display: flex;
                flex-direction: row;
                column-gap: 20px;
                align-items: center;
            }

            &__name {
                font-weight: 600;
                font-size: 18px;
                line-height: 132%;
                color: #00082c;
                display: flex;
                flex-direction: row;
            }

            &__date, &__time, &__place {
                display: flex;
                align-items: center;
                column-gap: 10px;
            }

            &__status {
                display: flex;
                align-items: center;
                column-gap: 10px;
                width: 20px;
                img {
                    width: 20px;
                }
            }
        }

        &__right {
            display: flex;
            align-items: center;
            opacity: 1;
            transition: all 0.2s ease-in-out;
            position: relative;

            &__button {
                position: relative;
                display: flex;
                align-items: center;
                padding: 8px 20px;
                background: #ffffff;
                border: 1px solid #bbc2ce;
                box-sizing: border-box;
                border-radius: 4px;
                margin-right: 8px;
                transition: all 0.2s ease-in-out;
                column-gap: 5px;
                color: #000000;

                &:hover {
                    background-color: #e8ebef;
                    color: #000000;
                }

                &:hover .session-item__right__button__text {
                    display: flex;
                }

                &__text {
                    display: flex;
                }
            }
        }
    }
</style>