<template>
    <aside class="side-bar-interactive border-right">
        <section class="side-bar-interactive__panel" v-if="discussionModule == 1">
            <header class="side-bar-interactive__panel__header">
                <img src="../../../assets/imgs/esesjalogo-big.png" class="side-bar__logo" style="width: 145px" />
            </header>
            <section class="side-bar-interactive__content">
                <section class="side-bar-interactive__content__top">
                    <h2 class="side-bar-interactive__content__title">
                        Dyskusja
                    </h2>
                    <section class="side-bar-interactive__content__actions">
                        <SearchQuestButton @click="focusSearchButton" />
                    </section>
                    <section class="side-bar-interactive__content__speechrequest" v-if="discussionModule === 1">
                        <eSesjaTab v-if="questionModule === 1">
                            <eSesjaTabNav id="speechRequests" :withoutMargins="true" class="disucssion-tabs" :isStrength="true">
                                <eSesjaTabNavItem id="speechRequests-discussion-tab"
                                                  href="speechRequests-discussion-content"
                                                  @click="() => activeTab = 'discussion'"
                                                  :isActive="true">
                                    <span class="tab-title">
                                        <img v-if="(isDummySpeechItem || currentActiveSpeech.userId !== 0) && activeTab !== 'discussion'"
                                            src="@/assets/icons/components/speaker.svg"
                                            alt="Trwa wypowiedź"
                                            class="user-speech-image" />
                                            <span class="position-relative">
                                                Zgłoszenia
                                                <span v-if="currentSpeechList.length > 0" class="position-absolute top-0 start-100 translate-middle badge bg-danger rounded-pill custom-pill" style="background: red;">
                                                    {{ currentSpeechList.length }}
                                                    <span class="visually-hidden"></span>
                                                </span>
                                            </span>
                                    </span>
                                </eSesjaTabNavItem>
                                <eSesjaTabNavItem id="speechRequests-question-tab"
                                                  href="speechRequests-question-content"
                                                  @click="() => activeTab = 'question'">
                                    <span class="position-relative">
                                        Pytania
                                        <span v-if="questionSpeechList.length > 0" class="position-absolute top-0 start-100 translate-middle badge bg-danger rounded-pill custom-pill" style="background: red;">
                                            {{ questionSpeechList.length }}
                                            <span class="visually-hidden"></span>
                                        </span>
                                    </span>
                                   
                                </eSesjaTabNavItem>
                                <eSesjaTabContent>
                                    <eSesjaTabPane tabId="speechRequests-discussion-content" :isActive="true">
                                        <perfect-scrollbar>
                                            <DummySpeechItem v-if="isDummySpeechItem && currentActiveSpeech.userId === 0" />
                                            <SpeechRequestItem v-if="currentActiveSpeech.userId !== 0"
                                                            :requestUserItem="currentActiveSpeech"
                                                            @addTimeToSpeakerEmit="addTimeToSpeakers"
                                                            @endParticipantSpeech="endParticipantSpeech" />

                                            <SpeechRequestItemGhost v-if="showRequestSpeechGhostSpecialItem" />

                                            <div v-for="(item, index) in currentSpeechList" :key="index" class="side-bar-interactive__content__speechrequest__item">
                                                <SpeechRequestItem :requestUserItem="item"
                                                                @endParticipantSpeech="endParticipantSpeech"
                                                                @giveParticipantSpeech="giveParticipantSpeechFromRequestList"
                                                                @removeFromRequestList="removeFromRequestList" />
                                            </div>
                                            <SpeechRequestItemGhost v-if="showRequestSpeechGhostItem" />

                                        </perfect-scrollbar>
                                    </eSesjaTabPane>
                                    <eSesjaTabPane tabId="speechRequests-question-content">
                                        <perfect-scrollbar>
                                            <div v-for="(item, index) in questionSpeechList" :key="index" class="side-bar-interactive__content__speechrequest__item">
                                                <SpeechRequestItem :requestUserItem="item"
                                                                @endParticipantSpeech="endParticipantSpeech"
                                                                @giveParticipantSpeech="giveParticipantSpeechFromRequestList"
                                                                @removeFromRequestList="removeFromRequestList" />
                                            </div>
                                        </perfect-scrollbar>
                                    </eSesjaTabPane>
                                </eSesjaTabContent>
                            </eSesjaTabNav>
                        </eSesjaTab>

                        <perfect-scrollbar v-else>
                            <DummySpeechItem v-if="isDummySpeechItem && currentActiveSpeech.userId === 0" />
                            <SpeechRequestItem v-if="currentActiveSpeech.userId !== 0"
                                            :requestUserItem="currentActiveSpeech"
                                            @addTimeToSpeakerEmit="addTimeToSpeakers"
                                            @endParticipantSpeech="endParticipantSpeech" />

                            <SpeechRequestItemGhost v-if="showRequestSpeechGhostSpecialItem" />

                            <div v-for="(item, index) in currentSpeechList" :key="index" class="side-bar-interactive__content__speechrequest__item">
                                <SpeechRequestItem :requestUserItem="item"
                                                @endParticipantSpeech="endParticipantSpeech"
                                                @giveParticipantSpeech="giveParticipantSpeechFromRequestList"
                                                @removeFromRequestList="removeFromRequestList" />
                            </div>
                            <SpeechRequestItemGhost v-if="showRequestSpeechGhostItem" />

                        </perfect-scrollbar>
                    </section>
                </section>
                <section class="side-bar-interactive__content__bottom">
                    <DiscussionQuestSearcher v-if="discussionModule == 1"
                                             ref="discussionQuestSearcherRef"
                                             :currentActiveSpeech="currentActiveSpeech"
                                             :availableQuestList="availableSpeechList"
                                             :currentRequestList="currentSpeechList"
                                             @addParticipantToRequestSpeechList="addParticipantToRequestSpeechList"
                                             @giveParticipantSpeech="giveParticipantSpeechFromQuestList"
                                             @removeParticipant="removeParticipant"
                                             @addParticipantToFavorite="addParticipantToFavorite"
                                             @removeParticipantFromFavorite="removeParticipantFromFavorite" />
                </section>
            </section>
        </section>
        <Transition>
            <CheckQorumModal v-if="$store.state.interactiveStore.isShowCheckQourum"
                             :presencesData="sessionUserPresenceData"
                             @closePresenceModal="closePresenceModal"
                             @saveUserPresents="saveCurrentUserPresents" />
        </Transition>
        <Transition>
            <ResultCheckQorumModal v-if="$store.state.interactiveStore.isShowResultCheckQourum"
                             :quorumresult="quorumresult"
                             :isPublicView="false"
                             @closeResultCheckQuorumModal="closeResultCheckQuorum" />
        </Transition>
        <Transition>
            <SettingCustomBreak v-if="$store.state.interactiveStore.isShowCustomBreakModal"
                                @startBreake="startSessionBrake" />
        </Transition>

    </aside>
</template>
<script charset="utf-8">
    import SpeechRequestItem from "./SpeechRequestItem.vue";
    import DiscussionQuestSearcher from "./DiscussionQuestSearcher.vue";
    //import UserPresenceModal from "./Modals/UserPresenceModal.vue"
    import CheckQorumModal from "./Modals/CheckQorumModal.vue"
    import ResultCheckQorumModal from "./Modals/QuorumResultModal.vue";
    import SettingCustomBreak from "./Modals/SettingCustomBreak.vue"
    import InteractiveService from "../../../services/interactive.service";
    import AlertHelper from '@/helpers/alerts.helper.js';
    import eSesjaSocketAction from "../../../services/esesja.socket.action";
    import eSesjaInteractiveService from "../../../services/esesja.interactive.service";
    import SpeechRequestItemGhost from "./SpeechRequestItemGhost.vue";
    import SearchQuestButton from "./Discussion/SearchQuestButton.vue";
    import DummySpeechItem from "./DummySpeechItem.vue";
    import speechTimerService from "../../../services/speech-timer.service";
    import { encryptStorage } from '../../../services/encrypt.service';

    //import CommonHelper from '@/helpers/common.helper.js';
    //import eSesjaDiscussionListState from "../../../services/esesja.discussion.list.state";
    export default {
        name: "DiscussionInteractive",
        components: {
            SpeechRequestItem,
            DiscussionQuestSearcher,
            //UserPresenceModal,
            SettingCustomBreak,
            CheckQorumModal,
            ResultCheckQorumModal,
            SpeechRequestItemGhost,
            SearchQuestButton,
            DummySpeechItem
        },
        computed: {
            sessionId() {
                return parseInt(this.$route.params.sessionid);
            },
            isDummySpeechItem(){
                return this.$store.state.speechTimerStore.selectedSpeechTime != null &&
                this.$store.state.speechTimerStore.currentSpeechTimestamp != null &&
                this.$store.state.speechTimerStore.estimatedSpeechEndTimestamp != null
            }
        },
        watch: {
            '$store.state.interactiveStore.isShowCheckQourum': function () {
                if (this.$store.state.interactiveStore.isShowCheckQourum == true) {
                    this.getUserPresences();
                }
            }
        },
        data() {
            return {
                quorumresult: [],
                availableSpeechList: [],
                currentSpeechList: [],
                questionSpeechList: [],
                sessionUserPresenceData: [],
                currentActiveSpeech: {
                    userId: 0,
                    discussionId: -1,
                    isSpeech: false,
                },
                tempGuestListRequest: [],
                tempGuestListSpeeker: [],
                usersAvatars: [],
                discussionModule: encryptStorage.getItem('modules').d,
                questionModule: encryptStorage.getItem('modules').q,
                showRequestSpeechGhostItem: false,
                showRequestSpeechGhostSpecialItem: false,
                activeTab: "discussion"
            }
        },
        async mounted() {
            this.registerInteractiveEvents();
            await this.getUserPresences();
            await this.getAvailableGuests();
            await this.getCurrentRequestList();
        },
        unmounted() {
            this.unregisterInteractiveEvents();
        },
        methods: {
            isInteractive() {
                return this.$store.state.appStore.isInInteractiveMode;
            },
            async closeResultCheckQuorum() {
                this.$store.commit('hideResultCheckQourum');
                this.quorumresult = [];

                if (this.isInteractive()) {
                    await eSesjaInteractiveService.closeResultCheckQuorumModal(this.sessionId);
                }
            },
            isDiscussionModule() {
                if (this.discussionModule === 1)
                    return true;

                return false;
            },

            isQuestionModule(){
                if(this.questionModule === 1){
                    return true;
                }
                return false;
            },

            focusSearchButton() {
                this.$refs.discussionQuestSearcherRef.$refs.requestSearchInput.$refs.searchInputRef.focus();
            },
            async getCurrentRequestList() {
                if (this.isDiscussionModule()) {
                    try {
                        let res = await InteractiveService.getCurrentRequestList(this.sessionId);

                        if (res.status == 200) {
                            this.currentSpeechList = [...res.data.discussRequestList];

                            if(this.isQuestionModule()){
                                const requestList = [...res.data.discussRequestList];
                                this.currentSpeechList = [...requestList.filter(item => item.requestType === 1)]
                                this.questionSpeechList = [...requestList.filter(item => item.requestType === 2)]
                            }

                            if (this.currentActiveSpeech.userId !== res.data.currentSpeech.userId) {
                                this.currentActiveSpeech = res.data.currentSpeech;
                                this.currentActiveSpeech.isSpeech = true;

                                if(this.isDummySpeechItem){
                                    await speechTimerService.endSpeechTimer(this.sessionId)
                                }
                            }

                            if (this.tempGuestListRequest.length > 0) {
                                for (var i = 0; i < this.tempGuestListRequest.length; i++) {

                                    var findElem = res.data.discussRequestList.findIndex(x => x.userId == this.tempGuestListRequest[i].guest.uid);
                                    if (findElem == -1) {
                                        this.availableSpeechList.splice(this.tempGuestListRequest[i].index, 0, this.tempGuestListRequest[i].guest);
                                        this.tempGuestListRequest.splice(this.tempGuestListRequest.indexOf(this.tempGuestListRequest[i]), 1);
                                    }
                                }
                                
                            }
                        }

                    }
                    catch (e) {
                        var t = this.$swal.mixin(AlertHelper.errorToastElement());
                        t.fire(AlertHelper.errorToastAlert('Błąd pobrania listy dyskutantów'));
                    }
                }
            },

            async closePresenceModal() {
                await this.getUserPresences();
            },

            async getAvailableGuests() {
                if (this.isDiscussionModule()) {
                    try {
                        let res = await InteractiveService.getAvailableGuests();

                        if (res.status == 200)
                            this.availableSpeechList = res.data;
                    }
                    catch (e) {
                        var t = this.$swal.mixin(AlertHelper.errorToastElement());
                        t.fire(AlertHelper.errorToastAlert('Błąd pobrania gości'));
                    }
                }
            },

            async getUserPresences() {
                try {
                    let res = await InteractiveService.getUserPresences(this.sessionId);
                    if (res.status == 200)
                        this.sessionUserPresenceData = res.data;
                }
                catch (e) {
                    var t = this.$swal.mixin(AlertHelper.errorToastElement());
                    t.fire(AlertHelper.errorToastAlert('Błąd pobrania obecności'));
                }
            },

            async giveParticipantSpeechFromQuestList(participant) {

                if (this.isDiscussionModule()) {
                    try {
                        if(this.isDummySpeechItem){
                            await speechTimerService.endSpeechTimer(this.sessionId)
                        }

                        await eSesjaInteractiveService.giveUserSpeech(participant.uid, -1, this.sessionId, 0);
                        this.$store.commit("showSuccessCustomMessage", `Pomyślnie przekazano głos dla <b>${participant.name} ${participant.surname}</b>`);

                        var index = this.availableSpeechList.findIndex(x => x.uid == participant.uid);

                        if (index != -1) {
                            var o = { index: index, guest: this.availableSpeechList[index] };
                            this.tempGuestListSpeeker.push(o);
                            this.availableSpeechList.splice(index, 1);
                        }
                    }
                    catch (e) {
                        var t = this.$swal.mixin(AlertHelper.errorToastElement());
                        t.fire(AlertHelper.errorToastAlert('Błąd udzielenia głosu'));
                    }
                }
            },
            async giveParticipantSpeechFromRequestList(participant) {

                if (this.isDiscussionModule()) {
                    try {
                        if(this.isDummySpeechItem){
                            await speechTimerService.endSpeechTimer(this.sessionId)
                        }

                        await eSesjaInteractiveService.giveUserSpeech(participant.userId, participant.discussionId, this.sessionId, 1);
                        this.$store.commit("showSuccessCustomMessage", `Pomyślnie przekazano głos dla <b>${participant.name} ${participant.surname}</b>`);
                    }
                    catch (e) {
                        var t = this.$swal.mixin(AlertHelper.errorToastElement());
                        t.fire(AlertHelper.errorToastAlert('Błąd udzielenia głosu'));
                    }

                    this.reOrderList();
                }
            },
            async addParticipantToRequestSpeechList(data) {
                if (this.isDiscussionModule()) {
                    try {

                        if(data.type !== undefined && data.type !== 2)
                            this.showRequestSpeechGhostSpecialItem = true;
                        else
                            this.showRequestSpeechGhostItem = true;

                        console.log(data);
                        await eSesjaInteractiveService.addUserToRequestSpeech(data.participant.uid, this.sessionId, data.type, data.requestType);

                        //let displayName = '';

                        //if (data.participant.name !== undefined && data.participant.name !== null)
                        //    displayName += `${data.participant.name}`;

                        //if (data.participant.surname !== undefined && data.participant.surname !== null)
                        //    displayName += ` ${data.participant.surname}`

                        //this.$store.commit("showSuccessCustomMessage", `Pomyślnie dodano <b>${displayName}</b> do listy chętnych osób do zabrania głosu`);

                        var index = this.availableSpeechList.findIndex(x => x.uid == data.participant.uid);
                        if (index !== -1) {
                            var o = { index: index, guest: this.availableSpeechList[index] };
                            this.tempGuestListRequest.push(o);
                            this.availableSpeechList.splice(index, 1);
                        }
                    }
                    catch (e) {
                        console.log(e);
                        var t = this.$swal.mixin(AlertHelper.errorToastElement());
                        t.fire(AlertHelper.errorToastAlert('Błąd dodania do listy dyskutantów'));
                    }
                }

            },
            async removeParticipant(participant) {
                if (this.isDiscussionModule()) {
                    try {
                        this.$store.commit("showLoader");
                        await eSesjaInteractiveService.removeGuest(participant.uid);

                        let userDisplay = `${participant.name}`;
                        if (participant.surname !== null)
                            userDisplay += ` ${participant.surname}`;

                        this.$store.commit("showSuccessCustomMessage", `Pomyślnie usunięto <b>${userDisplay}</b>`);
                    }
                    catch (e) {
                        var t = this.$swal.mixin(AlertHelper.errorToastElement());
                        t.fire(AlertHelper.errorToastAlert('Błąd usunięcia uczestnika'));
                    }
                    finally {
                        this.$store.commit("hideLoader");
                    }
                }
            },
            async removeParticipantFromFavorite(participant) {
                if (this.isDiscussionModule()) {
                    try {
                        this.$store.commit("showLoader");
                        await eSesjaInteractiveService.addUserToFavourite(participant.uid);

                        let userDisplay = `${participant.name}`;
                        if (participant.surname !== null)
                            userDisplay += ` ${participant.surname}`;

                        this.$store.commit("showSuccessCustomMessage", `Pomyślnie usunięto z ulubionych <b>${userDisplay}</b>`);
                    }
                    catch (e) {
                        var t = this.$swal.mixin(AlertHelper.errorToastElement());
                        t.fire(AlertHelper.errorToastAlert('Błąd usunięcia z ulubionych'));
                    }
                    finally {
                        this.$store.commit("hideLoader");
                    }
                }
            },
            async addParticipantToFavorite(participant) {
                if (this.isDiscussionModule()) {
                    try {
                        this.$store.commit("showLoader");
                        await eSesjaInteractiveService.addUserToFavourite(participant.uid);

                        let userDisplay = `${participant.name}`;
                        if (participant.surname !== null)
                            userDisplay += ` ${participant.surname}`;

                        this.$store.commit("showSuccessCustomMessage", `Pomyślnie dodano <b>${userDisplay}</b> ulubionych`);
                    }
                    catch (e) {
                        var t = this.$swal.mixin(AlertHelper.errorToastElement());
                        t.fire(AlertHelper.errorToastAlert('Błąd dodania do ulubionych'));
                    }
                    finally {
                        this.$store.commit("hideLoader");
                    }
                }
            },
            async removeFromRequestList(participant) {
                if (this.isDiscussionModule()) {
                    try {
                        await eSesjaInteractiveService.removeUserFromRequestSpeech(participant.userId, this.sessionId, participant.discussionId);

                        //let displayName = '';

                        //if (participant.name !== undefined && participant.name !== null)
                        //    displayName += `${participant.name}`;

                        //if (participant.surname !== undefined && participant.surname !== null)
                        //    displayName += ` ${participant.surname}`

                        //this.$store.commit("showSuccessCustomMessage", `Pomyślnie usunięto <b>${displayName}</b> z listy chętnych osób do zabrania głosu`);

                        var tempGuest = this.tempGuestListRequest.findIndex(x => x.guest.uid == participant.userId);
                        if (tempGuest != -1) {
                            this.availableSpeechList.splice(this.tempGuestListRequest[tempGuest].index, 0, this.tempGuestListRequest[tempGuest].guest);
                            this.tempGuestListRequest.splice(tempGuest, 1);
                        }
                    }
                    catch (e) {
                        var t = this.$swal.mixin(AlertHelper.errorToastElement());
                        t.fire(AlertHelper.errorToastAlert('Błąd usunięcia z listy dyskutantów'));
                    }
                }
            },
            async addTimeToSpeakers(data) {
                if (this.isDiscussionModule()) {
                    await eSesjaInteractiveService.addTimeToSpeakerForPublic(data.item.userId, this.sessionId, data.item.discussionId, data.time);
                }
            },
            async endParticipantSpeech(participant) {
                if (this.isDiscussionModule()) {
                    try {
                        await eSesjaInteractiveService.endUserSpeech(participant.userId, participant.discussionId, this.sessionId);

                        let displayName = '';

                        if (participant.name !== undefined && participant.name !== null)
                            displayName += `${participant.name}`;

                        if (participant.surname !== undefined && participant.surname !== null)
                            displayName += ` ${participant.surname}`

                        this.$store.commit("showSuccessCustomMessage", `Pomyślnie zakończono wypowiedź <b>${displayName}</b>`);

                        var tempGuest = this.tempGuestListSpeeker.findIndex(x => x.guest.uid == participant.userId);
                        if (tempGuest != -1) {
                            this.availableSpeechList.splice(this.tempGuestListSpeeker[tempGuest].index, 0, this.tempGuestListSpeeker[tempGuest].guest);
                            this.tempGuestListSpeeker.splice(tempGuest, 1);
                        }
                    }
                    catch (e) {
                        var t = this.$swal.mixin(AlertHelper.errorToastElement());
                        t.fire(AlertHelper.errorToastAlert('Błąd zabrania głosu'));
                    }
                    // finally{
                    //     this.$store.commit("setSelectedSpeechTime", null);
                    //     this.$store.commit("setCurrentSpeechTimestamp", null);
                    //     this.$store.commit("setEstimatedSpeechEndTimestamp", null);
                    // }
                }
            },

            reOrderList() {
                this.currentSpeechList.sort((a, b) => b.isSpeech - a.isSpeech);
            },

            async saveCurrentUserPresents(presentData) {
                try {
                    this.$store.commit("showLoader");

                    var data = { sessionId: this.sessionId };
                    await InteractiveService.stopCheckQuorum(data);

                    let res = await InteractiveService.saveUserPresences(this.sessionId, true, presentData);

                    if (res.status === 200)
                        this.sessionUserPresenceData = res.data;

                    this.$store.commit("showSuccessCustomMessage", `Obecność zapisana poprawnie`);
                }
                catch (e) {
                    var t = this.$swal.mixin(AlertHelper.errorToastElement());
                    t.fire(AlertHelper.errorToastAlert('Błąd zapisu obecności'));
                }
                finally {
                    this.$store.commit('hidePresenceList');
                    this.$store.commit('hideCheckQourum');
                    this.$store.commit("hideLoader");
                }
            },

            async startSessionBrake(breakDuration) {
                try {
                    let timestamp = new Date();
                    this.$store.commit("setBreakTimestamp", timestamp);
                    this.$store.commit("hideCustomBreakModal");
                    this.$store.commit("showLoader");
                    this.$store.commit("setBreakDuration", breakDuration);
                    this.$store.commit("startBreak");
                    
                    await eSesjaInteractiveService.startBreak(breakDuration, this.$store.state.appStore.sessionId, timestamp);

                }
                catch (e) {
                    var t = this.$swal.mixin(AlertHelper.errorToastElement());
                    t.fire(AlertHelper.errorToastAlert('Błąd rozpoczęcia przerwy'));
                }
                finally {
                    this.$store.commit("hideLoader");
                }
            },
            registerInteractiveEvents() {
                eSesjaInteractiveService.events.on(eSesjaSocketAction.DiscussionListChanged.name, this.discussionChangeCallOtherHubs)
                eSesjaInteractiveService.events.on("SignalRUpdateDiscussionListAdmin", this.UpdateDiscussionListAdmin)
                eSesjaInteractiveService.events.on("SignalRRefreshGuestLists", this.refreshGuestLists)
                eSesjaInteractiveService.events.on("SignalRQuorumSummaryReults", this.showResultQuorumModal)
            },
            unregisterInteractiveEvents() {
                eSesjaInteractiveService.events.removeListener(eSesjaSocketAction.DiscussionListChanged.name, this.discussionChangeCallOtherHubs);
                eSesjaInteractiveService.events.removeListener("SignalRUpdateDiscussionListAdmin", this.UpdateDiscussionListAdmin)
                eSesjaInteractiveService.events.removeListener("SignalRRefreshGuestLists", this.refreshGuestLists);
                eSesjaInteractiveService.events.removeListener("SignalRQuorumSummaryReults", this.showResultQuorumModal);
            },

            async UpdateDiscussionListAdmin(res) {
                if (this.isDiscussionModule()) {
                    try {
                        if (res != null) {
                            this.currentSpeechList = [...res.discussRequestList];

                            if(this.isQuestionModule()){
                                const requestList = [...res.discussRequestList];
                                this.currentSpeechList = [...requestList.filter(item => item.requestType === 1)]
                                this.questionSpeechList = [...requestList.filter(item => item.requestType === 2)]
                            }

                            if (this.currentActiveSpeech.userId !== res.currentSpeech.userId) {
                                this.currentActiveSpeech = res.currentSpeech;
                                this.currentActiveSpeech.isSpeech = true;

                                if(this.isDummySpeechItem){
                                    await speechTimerService.endSpeechTimer(this.sessionId)
                                }
                            }

                            if (this.tempGuestListRequest.length > 0) {
                                for (var i = 0; i < this.tempGuestListRequest.length; i++) {

                                    var findElem = res.discussRequestList.findIndex(x => x.userId == this.tempGuestListRequest[i].guest.uid);
                                    if (findElem == -1) {
                                        this.availableSpeechList.splice(this.tempGuestListRequest[i].index, 0, this.tempGuestListRequest[i].guest);
                                        this.tempGuestListRequest.splice(this.tempGuestListRequest.indexOf(this.tempGuestListRequest[i]), 1);
                                    }
                                }
                            }
                        }
                    }
                    catch (e) {
                        var t = this.$swal.mixin(AlertHelper.errorToastElement());
                        t.fire(AlertHelper.errorToastAlert('Błąd pobrania listy dyskutantów'));
                    }
                    finally {
                        this.showRequestSpeechGhostItem = false;
                        this.showRequestSpeechGhostSpecialItem = false;
                    }
                }
            },

            async discussionChangeCallOtherHubs(data) {
                if (this.isDiscussionModule()) {
                    try {
                        await eSesjaInteractiveService.discussionChangeCallOtherHubs(data.userId, this.sessionId, data.state.name);
                    }
                    catch (e) {
                        var t = this.$swal.mixin(AlertHelper.errorToastElement());
                        t.fire(AlertHelper.errorToastAlert('Błąd powiadomienia o zmianie listy dyskutantów'));
                    }
                }
            },

            showResultQuorumModal(data) {
                this.quorumresult = data;
                this.$store.commit('showResultCheckQourum');
            },

            refreshGuestLists(data) {
                if (this.isDiscussionModule()) {
                    try {
                        this.availableSpeechList = data;
                    }
                    catch (e) {
                        var t = this.$swal.mixin(AlertHelper.errorToastElement());
                        t.fire(AlertHelper.errorToastAlert('Błąd odświeżenia listy gości'));
                    }
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .border-right {
        border-right: 1px solid #dde0e7
    }

    .side-bar-interactive {
        height: 100vh;
        background: #fff;
        min-width: 319px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: sticky;
        top: 0px;
        z-index: 7;

        .bg-danger{
            background-color: #d80418 !important;
            color: #fff; 
        }

        .rounded-pill{
            border-radius: 50% !important;
        }

        .custom-pill{
            left: 125% !important;
        }

        .tab-title{
            display: flex;
            flex-direction: row;
            gap: 5px;
            justify-content: center;
            .user-speech-image{
                width: 20px;
                height: 20px;
                animation: pulse 2s infinite;
            }

            @keyframes pulse {
                0% {
                    opacity: 0;
                }

                50% {
                    opacity: 1;
                }

                100% {
                    opacity: 0;
                }
            }
        }
        .disucssion-tabs{
            max-width: 319px;
            width: 100% !important;
            .nav {
                width: 100% !important;
            }
            .tab-content{
                padding: 0 !important;
                width: 100%;
            }
        }
        .nav-tabs{
            margin-bottom: 0 !important;
        }
        @media only screen and (max-width:1600px) {
            min-width: auto;
            width: 315px;
        }

        &__panel {
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            &__header {
                width: 100%;
                box-sizing: border-box;
                padding: 25px 30px;
                justify-content: space-between;
                align-items: center;
                background-color: #fff;
                position: sticky;
                top: 0;
                z-index: 6;
                text-align: center;
                border-bottom: 1px solid #dde0e7;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-grow: 1;

            &__top {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
            }

            &__title {
                text-align: center;
                font-weight: 300;
                margin-top: 10px;
            }

            &__actions {
                display: flex;
            }

            &__speechrequest {
                .ps {
                    height: calc(100vh - 300px);
                }

                &__item {
                    background: #fff;

                    &:nth-child(even) {
                        background: #f2f2f2;
                    }
                }
            }
        }
    }
</style>