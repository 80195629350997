import ApiService from "./api.service";

class AdminESesjaService extends ApiService {
    async getBanner(){
        try{
            const result = await this.get("ads");
            return result;
        }
        catch{
            return null;
        }
    }
}
export default new AdminESesjaService();