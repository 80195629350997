import axios from "axios";
import store from "../store/mainStore";
import router from "../router/main-router";
import { encryptStorage } from '../../src/services/encrypt.service';
import UserService from "../services/user.service";
import Swall from 'sweetalert2/dist/sweetalert2.js';

export default function axiosSetup() {
    axios.interceptors.request.use(
        config =>{
            const token = encryptStorage.getItem("token");
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            return config;
        },
        error => {
            // Do something with request error
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use(
        response => {
            return response;
        },
        async error => {
            console.log(error);
            if (error.response != undefined &&
                error.response.config.url !== "Auth/me" &&
                error.response.config.url !== "Auth/login" &&
                error.response.config.url !== "Auth/refresh" &&
                error.response.config.url !== "user/profile" &&
                error.response &&
                error.response.status !== 404 &&
                error.response.status !== 422)
            {
                store.commit("showError");
            }
            store.commit("hideLoader");

            if (error.response && error.response.status === 401) {
                await UserService.destroyer();
                throw new axios.Cancel(error.response).message.data;
            }

            if (error.response && error.response.status === 403) {
                await router.push({ name: "home" });
            }

            //if (error.response && error.response.status === 422) {
            //    throw new axios.Cancel(error.response).message.data;
            //}

            if (error.response && error.response.status === 500) {
                Swall.fire({
                    title: "Błąd połączenia",
                    html: `Wykryto błąd połączenia z serwerami.<br />Sprawdź status serwerów<br /><a href='https://status.esesja.pl/' target="_blank" style="text-decoration: underline;text-transform: uppercase;">Status serwerów</a>`,
                    icon: "error"
                });
            }

            return Promise.reject(error);
        }
    );
}
