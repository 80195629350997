<template>
    <ul :class="classes" :id="id" role="tablist" v-if="mode === horizontal" :style="styles">
        <slot></slot>
    </ul>
    <div class="d-flex align-items-start" v-else>
        <div :class="classes" id="id" role="tablist" aria-orientation="vertical" :style="styles">
            <slot></slot>
        </div>
    </div>
</template>
<script charset="utf-8">
    export default {
        name: "eSesjaTabNav",
        props: {
            id: {
                type: String,
                default: "default-tab"
            },
            mode: {
                type: String,
                default: "horizontal"
            },
            type: {
                type: String,
                default: "default"
            },
            withoutMargins: {
                type: Boolean,
                default: false,
            },
            isStrength: {
                type: Boolean,
                default: false,
            }
        },
        computed: {
            classes() {
                let className = "";

                switch (this.mode) {
                    case "horizontal": className += "nav nav-tabs"; break;
                    case "vertical": className += "nav flex-column nav-pills"; break;
                }

                if(!this.withoutMargins){
                    switch (this.mode) {
                        case "horizontal": className += " mb-3"; break;
                        case "vertical": className += " me-3"; break;
                    }

                }

                if(this.isStrength){
                    switch (this.mode) {
                        case "horizontal": className += " nav-fill"; break;
                    }

                }

                switch(this.type){
                    case "pills": className += " nav-pills" ; break;
                }

                return className;
            },
            styles(){
                return {
                    width: this.isStrength ? '100%' : ''
                }
            }
        }
    }
</script>