<template>
    <section class="add-note">
        <section class="add-note-form">
            <button class="add-note-form__exit-btn"
                    @click="
                    (e)=>
                {
                e.preventDefault();
                this.$emit('closeRegisterModal');
                }
                "
                >
                <img src="../../../../../assets/icons/components/exit-btn.svg" />
            </button>
            <h2 class="add-note-form__title">Ustawienia dokumentu</h2>
            <section class="add-note-form__input-wrapper">
                <label class="add-note-form__label">Numer uchwały</label>
                <input type="number"
                       placeholder="Wpisz numer uchwały..."
                       class="add-note-form__input"
                       v-model="number" @input="changeHandler"
                       v-error="{ errors,input_key: 'number',}" />
                <ErrorMessage v-if="isError('number', this.errors)"
                              :errorMsgs="passErrorMsg('number', this.errors)" />
            </section>

            <section class="add-note-form__input-wrapper">
                <label class="add-note-form__label">Nazwa uchwały</label>
                <textarea
                       maxlength="500"
                       rows="3"
                       class="add-note-form__input add-note-form__input-with-chars-counter"
                       v-model="name">
                </textarea>
                <CharactersCounter :text="name" maxLenght=500 />
            </section>

            <section class="add-note-form__input-wrapper">
                <label class="add-note-form__label">Podstawa prawna</label>
                <textarea
                       maxlength="450"
                       rows="3"
                       class="add-note-form__input add-note-form__input-with-chars-counter"
                       v-model="legalBasis"></textarea>
                <CharactersCounter :text="legalBasis" maxLenght=450 />
            </section>

            <section class="add-note-form__input-wrapper">
                <label class="add-note-form__label">Uwagi</label>
                <textarea maxlength="4000" class="add-note-form__wyswig" v-model="desc"></textarea>
                <CharactersCounter :text="desc" maxLenght=4000 />
            </section>

            <div class="form-bootstrap-check form-bootstrap-switch">
                <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="is-announced" v-model="isAnnounced" />
                <label class="form-bootstrap-check-label" for="is-announced">Data ogłoszenia</label>
            </div>

            <section class="edit-meeting__row-double">
                <section class="edit-meeting__col-double" v-if="isAnnounced">
                    <section class="edit-meeting__picker-wrapper">
                        <input type="date"
                               class="edit-meeting__picker"
                               v-model="dateAnnounced" @input="changeHandler"
                               :disabled="!isAnnounced" v-error="{ errors,input_key: 'dateAnnounced',}" />
                        <ErrorMessage v-if="isError('dateAnnounced', this.errors)"
                                      :errorMsgs="passErrorMsg('dateAnnounced', this.errors)" />
                    </section>
                </section>
            </section>

            <div class="form-bootstrap-check form-bootstrap-switch">
                <input class="form-bootstrap-check-input" type="checkbox" role="switch" id="is-valid" v-model="isValid" />
                <label class="form-bootstrap-check-label" for="is-valid">Okres obowiązywania</label>
            </div>

            <section class="edit-meeting__row-double">
                <section class="edit-meeting__col-double" v-if="isValid">
                    <section class="edit-meeting__picker-wrapper">
                        <input type="date"
                               class="edit-meeting__picker"
                               v-model="dateFrom" @input="changeHandler"
                               :disabled="!isValid" v-error="{ errors,input_key: 'dateFrom',}" />
                        <ErrorMessage v-if="isError('dateFrom', this.errors)"
                                      :errorMsgs="passErrorMsg('dateFrom', this.errors)" />
                    </section>
                </section>

                <section class="edit-meeting__col-double" v-if="isValid">
                    <section class="edit-meeting__picker-wrapper">
                        <input type="date"
                               class="edit-meeting__picker"
                               v-model="dateTo" @input="changeHandler"
                               :disabled="!isValid" v-error="{ errors,input_key: 'dateTo',}" />
                        <ErrorMessage v-if="isError('dateTo', this.errors)"
                                      :errorMsgs="passErrorMsg('dateTo', this.errors)" />
                    </section>
                </section>
            </section>

            <section class="add-note-form__bottom">
                <button class="add-note-form__bottom-button"
                        @click="this.$emit('closeRegisterModal')">
                    Anuluj
                </button>
                <button class="
                        add-note-form__bottom-button
                        add-note-form__bottom-button--red
                    "
                        @click="handleSaveClick">
                    Zapisz
                </button>
            </section>
        </section>
            <LoaderInside v-if="isLoading" />
        </section>
</template>

<script>
    import moment from "moment";
    import LoaderInside from "../../../../BasicControls/LoaderInside.vue";
    import RegisterService from "../../../../../services/register.service";
    import ErrorMessage from "../../../../BasicControls/ErrorMessage";
    import {
        passErrorMsg,
        isError,
        setErrors,
    } from "../../../../../formValidation/main";

    import CharactersCounter from "../../../../BasicControls/CharactersCounter.vue";

    export default {
        name: "EditRegister",
        data() {
            return {
                legalBasis: this.registerElement.legalBasis,
                desc: this.registerElement.desc,
                name: this.registerDocName,
                number: this.registerElement.number,
                isLoading: false,
                isAnnounced: this.registerElement.isAnnounced,
                isValid: this.registerElement.isValid,
                dateAnnounced: this.registerElement.announced != '' ? moment(this.registerElement.announced).format("YYYY-MM-DD") : '',
                dateFrom: this.registerElement.dateFrom != '' ? moment(this.registerElement.dateFrom).format("YYYY-MM-DD") : '',
                dateTo: this.registerElement.dateTo != '' ? moment(this.registerElement.dateTo).format("YYYY-MM-DD") : '', 
                errors: []
            };
        },
        props: {
            registerElement: Object,
            registerDocName: String,
            tempPidId: Number
        },
        components: {
            LoaderInside,
            ErrorMessage,
            CharactersCounter
        },
        methods: {
            changeHandler() {
                this.validate();
            },
            clean() {
                this.legalBasis = '';
                this.desc = '';
                this.name = '';
                this.number = '';
                this.isLoading = false;
                this.isAnnounced = false;
                this.isValid = false;
                this.dateAnnounced = '';
                this.dateFrom = '';
                this.dateTo = '';
            },
            handleSaveClick() {
                if (this.validate() == false)
                    return

                this.errors = [];
                this.isLoading = true;
                const data = {
                    legalBasis: this.legalBasis,
                    desc: this.desc,
                    number: this.number,
                    announced: this.isAnnounced,
                    valid: this.isValid,
                    registerName: this.name
                };


                if(this.isAnnounced == true){
                    data["registerTime"] = this.dateAnnounced
                }

                if (this.isValid == true) {
                    data["from"] = this.dateFrom;
                    data["to"] = this.dateTo;
                }

                RegisterService.updateRegister(this.registerElement.id, this.tempPidId, data)
                    .then(() => {
                        this.clean();
                        this.$emit("updateData");
                        this.$emit('closeRegisterModal');
                    });
                this.isLoading = false;
            },
            validate() {
                var bo = true;
                var err = {};
                if (this.number.length <= 0) {
                    err.number = ['Pole wymagane']
                    bo = false
                }

                if (this.isAnnounced == true) {
                    if (moment(this.dateAnnounced, 'yyyy-MM-DD', true).isValid() == false) {
                        err.dateAnnounced = ['Zły format daty']
                        bo = false
                    }
                }

                if (this.isValid == true) {
                    if (moment(this.dateFrom, 'yyyy-MM-DD', true).isValid() == false) {
                        err.dateFrom = ['Zły format daty']
                        bo = false
                    }

                    if (moment(this.dateTo, 'yyyy-MM-DD', true).isValid() == false) {
                        err.dateTo = ['Zły format daty']
                        bo = false
                    }

                    if (this.dateFrom > this.DateTo) {
                        err.dateTo = ['Zły zakres czasowy']
                        bo = false
                    }
                }

                if (!bo) {
                    this.isLoading = false;
                    this.errors = setErrors(err)
                } else {
                    this.isLoading = false;
                    this.errors = [];
                }
                return bo
            },
            passErrorMsg,
            isError,
            setErrors,
        },
    };
</script>

<style lang="scss" scoped>
    .add-note {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        background: #00082c75;
        z-index: 15;
    }

    .add-note-form {
        padding: 32px 56px;
        background: linear-gradient(146.23deg, #f4f8ff 0%, #ffffff 100%);
        border: 2px solid #ffffff;
        box-sizing: border-box;
        box-shadow: 24px 40px 120px rgba(0, 8, 44, 0.32);
        border-radius: 12px;
        max-height: 90vh;
        overflow-y: auto;
        width: 630px;

        &::-webkit-scrollbar {
            width: 12px;
            height: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background: #b5b8bd;
            border-radius: 30px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #b5b8bd;
        }

        &::-webkit-scrollbar-track {
            background: #ffffff;
            border: 0.8px solid #dce4ee;
            border-radius: 30px;
            box-shadow: inset 0px 0px 0px 0px #f0f0f0;
        }

        &__exit-btn {
            border: 0px;
            background-color: transparent;
            position: relative;
            right: -100%;
        }

        &__title {
            font-weight: 600;
            line-height: 128%;
            color: #00082c;
            margin-bottom: 10px;
        }

        &__input-wrapper {
            display: flex;
            flex-direction: column;
            margin-top: 2px;
        }

        &__label {
            font-weight: 600;
            font-size: 14px;
            line-height: 145%;
            color: #00082c;
        }

        &__input {
            padding: 12.5px 16px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            margin-top: 8px;
            font-size: 16px;
            line-height: 144%;
            color: #00082c;
            margin-bottom: 16px;

            &:focus {
                border: 1px solid #00082c;
            }
        }

        &__input-with-chars-counter{
            margin-bottom: 0px;
        }

        &__wyswig {
            margin-top: 8px;
            height: 150px;
            width: 510px;
            max-height: 150px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            border-radius: 4px;
        }

        &__bottom {
            display: flex;
            justify-content: space-between;
        }

        &__bottom-button {
            padding: 18px 40px 17px;
            width: 238px;
            height: 56px;
            border: 1px solid #bbc2ce;
            box-sizing: border-box;
            border-radius: 4px;
            font-weight: 500;
            font-size: 16px;
            line-height: 132%;
            color: #00082c;
            background-color: transparent;
            margin-top: 40px;
            background: none;

            &--red {
                border: 0px;
                background: #d80418;
                color: white;
            }

            &--red:disabled {
                border: 0px;
                background: #D3D3D3;
                color: white;
                cursor: default;
            }
        }
    }

    .switch-section {
        display: flex;
        margin-bottom: 0px;
        margin-top: 10px;
    }

    .tooltip {
        position: relative;
        left: 5px;
        top: -3px;
    }

    .tip-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0px;
        background-color: transparent;
        width: 20px;
        height: 20px;
        position: relative;
        cursor: pointer;

        &:hover .hint {
            opacity: 1;
            display: block;
        }
    }

    .edit-meeting {
        &__row-double {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0px;
        }

        &__col-double {
            display: flex;
            flex-direction: column;
        }

        &__picker {
            padding: 12.5px 16px;
            background: #ffffff;
            border: 1px solid #d1d6df;
            box-sizing: border-box;
            box-shadow: 1px 1px 3px rgba(0, 8, 44, 0.06);
            border-radius: 4px;
            min-width: 238px;
            height: 56px;
            font-size: 16px;
            line-height: 144%;
            color: #00082c;

            &[type="date"]::-webkit-calendar-picker-indicator {
                color: rgba(0, 0, 0, 0);
                opacity: 1;
                display: block;
                background: url("../../../../../assets/icons/components/callendar.svg") no-repeat;
                background-position-x: 100%;
                width: 22px;
                height: 22px;
                border-width: thin;
            }

            &[type="time"]::-webkit-calendar-picker-indicator {
                color: rgba(0, 0, 0, 0);
                opacity: 1;
                display: block;
                background: url("../../../../../assets/icons/components/clock.svg") no-repeat;
                width: 22px;
                height: 22px;
                border-width: thin;
            }
        }

        &__picker-wrapper {
            position: relative;

            &::after {
                content: "";
                position: absolute;
                top: 8px;
                background: #e2e5eb;
                width: 1px;
                height: 40px;
                right: 56px;
                z-index: 99;
            }
        }
    }

</style>

<style lang="scss">
    .ProseMirror {
        height: 160px;
        max-height: 160px;

        h1 {
            font-size: 32px;
        }

        h2 {
            font-size: 24px;
        }

        p {
            font-size: 16px;
        }
    }
</style>
